import React from 'react';
import {
    Navbar,
    NavbarBrand,
} from 'reactstrap';
import SubHeader from './sub-header';

const Header = () => {

    return (
        <>
            <Navbar color="primary" light expand="md" className='justify-content-between'>
                <NavbarBrand href='/login' className='mr-2 ml-0'>
                    <img src="/assets/images/logo-leve-natural.png" alt="Logo" height={60}></img>
                </NavbarBrand>
                <NavbarBrand href='/login' className='mr-0 ml-2'>
                    <img src="/assets/images/logo-alt.png" alt="Logo Hortifruti" height={60}></img>
                    <img src="/assets/images/logo-natural-terra.png" alt="Logo Natural da Terra" height={60} style={{ marginLeft: 10 }}></img>
                </NavbarBrand>
            </Navbar>
            <SubHeader></SubHeader>
        </>
    )
}

export default Header;