import React, { useEffect } from 'react';
import Routes from './routes';

const App = () => {

    useEffect(()=>{
        if(window.location.href.includes('cronoex.com.br') && window.location.protocol==='http:'){
            window.location.href = window.location.href.replace('http:/', 'https:/');
        }
    })

    return <Routes />
};


export default App;
