import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import './styles.scss';

const SubHeader = () => {
    const { clienteState } = useSelector((state: any) => state);

    const primeiroNome = function (nome: string = ''): string {
        if (nome.indexOf(' ') !== -1) {
            return nome.split(' ')[0];
        } else {
            return nome;
        }
    }

    return (

        <div className="sub-header py-1 px-3" style={{ fontSize: '80%' }}>
            <Switch>
                <Route path='/pedido'>
                    {clienteState.data.Nome
                        ? <b>{primeiroNome(clienteState.data.Nome)}, bem vindo(a)!</b>
                        : <b>Bem vindo(a)!</b>
                    }
                </Route>
                <Route path="*">
                    <b className='text-white'>Bem vindo ao mundo das cores e sabores!</b> 🌿🌱
                </Route>

            </Switch>


        </div>
    )

}
export default SubHeader;