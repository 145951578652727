import { ApiDefaultRes } from "./../types/ApiDefaultRes";
import { ApiPropzResponse } from "./../types/ApiPropzResponse";
import apiCronoex from "./apis/ApiCronoex";
class PromocaoService {
  static async getPromocoesByCpf(
    cpf: string,
    mechanic: Mechanic
  ): Promise<ApiPropzResponse> {
    const res = await apiCronoex.get("/cupons/listar/" + cpf, {
      params: {
        mechanic: mechanic,
      },
    });

    const data: ApiDefaultRes = res.data;

    return data.valor;
  }

  static async ativarPromocao(
    cpf: string,
    promotion_id: number
  ): Promise<boolean> {
    const data = {
      cliente: {
        CupomPedido: {
          cpf: cpf,
          cupom: "Cupom-Hortifruti",
          promocao: promotion_id,
        },
      },
    };

    const res = await apiCronoex.post(
      "/promocoes/ativar/" + cpf + "/" + promotion_id,
      data
    );

    const apiRes: ApiDefaultRes = res.data;

    if (
      apiRes.resultado &&
      apiRes.valor.mensagem === "CPF e promoção vinculados"
    ) {
      return true;
    } else {
      return false;
    }
  }
}

export type Mechanic = "spend_and_get_percent" | "reduced_price";

export default PromocaoService;
