import React, { useEffect } from 'react';
import { Button, Form, Input } from 'reactstrap';
import { ErrorOption, useForm } from 'react-hook-form';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import './styles.scss'
import { useHistory } from "react-router-dom";
import usePageTracking from '../../hooks/usePageTracking';
import { padStart } from 'lodash';
import LoginService from '../../services/LoginService';

const Login = (props: any) => {
    usePageTracking();
    const { register, handleSubmit, setValue, formState: { errors }, setError, clearErrors, watch } = useForm();
    const watchCpf = watch('Cpf');

    let history = useHistory();

    const getCpfCompleto = (cpf: string): string => {
        const cpfCompleto: string = cpf && cpf.length > 0 ? padStart(cpf, 11, '0') : '';
        return cpfCompleto;
    }

    const handlePosLogin = () => {
        history.push('/promocoes');
    }

    const validarForm = () => {
        const cpfCompleto = getCpfCompleto(watchCpf);

        const isCpfValido = cpfValidator.isValid(cpfCompleto);

        let errorOptions: ErrorOption = {
            message: ''
        };

        if (cpfCompleto.length === 11) {

            errorOptions.message = 'CPF inválido';

            if (!isCpfValido) {

                if (watchCpf.length === 11) {
                    setError('Cpf', errorOptions);
                }

                return false;
            } else {
                return true;
            }
        } else {
            errorOptions.message = 'CPF incompleto';
            setError('Cpf', errorOptions);
        }

        return false;
    }

    const onSubmit = async (data: any) => {
        const isValido = validarForm();
        if (!isValido) {
            return false;
        } else {
            clearErrors();
        }

        LoginService.doLoginByCpf(watchCpf);

        handlePosLogin();
    };

    const { REACT_APP_DEFAULT_LOGIN } = process.env;

    useEffect(() => {
        if (REACT_APP_DEFAULT_LOGIN) {
            setValue('Cpf', REACT_APP_DEFAULT_LOGIN);
        }
    }, [REACT_APP_DEFAULT_LOGIN, setValue]);

    return (

        <div className="d-flex justify-content-center py-5 ">

            <div className="row">
                <div style={{ width: '380px' }}>
                    <div className="shadow bg-white rounded">

                        <div className="p-3">
                            <p className="text-center">
                                Insira o CPF do Cliente Leve Natural e verifique se há algum cupom disponível para ser ativado (apenas números)
                            </p>

                            <Form className="m-3" onSubmit={handleSubmit(onSubmit)}>
                                <div className="d-flex justify-content-between mb-2">
                                    <Input placeholder="CPF" innerRef={register} name="Cpf" type='text' maxLength={11} />
                                </div>

                                {errors.Cpf && <div className="text-danger text-center">{errors.Cpf.message}</div>}

                                <div className="d-flex justify-content-center mb-2">
                                    <Button color="success" className="mt-3">
                                        Continuar
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}


export default Login