import axios from 'axios';
import getToken from './ApiCronoexAuth';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_CRONOEX_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

api.interceptors.request.use(
    async config => {

        if (config.url?.indexOf('/auth') === -1) {
            const token = await getToken();
            config.headers.Authorization = 'Bearer ' + token;
        }

        return Promise.resolve(config)
    },
    error => {
        return Promise.reject(error)
    },
)

export default api;