import { PromotionItem } from '../../types/PromotionItem';
import './PromocaoItem.scss';
import { Button } from 'reactstrap';
import PromocaoService from '../../services/PromocaoService';
import CpfService from '../../services/CpfService';
import moment from 'moment';
import AlertService from '../../services/AlertService';
import LoadingService from '../../services/LoadingService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

const PromocaoItemSpendAndGet = (props: PromocaoItemProps) => {
    const { item } = props;

    const ativarPromocao = async (item: PromotionItem) => {
        LoadingService.show('Ativando a promoção...');
        const cpf = CpfService.formatar(item.customerId.substring(item.customerId.length - 11));

        const res = await PromocaoService.ativarPromocao(cpf, item.promotionId);

        LoadingService.hide();


        if (res) {
            await props.carregarPromocoes();
            AlertService.show('Promoção ativada com sucesso!');

        }
    }

    return (
        <div className="card promocao-item m-2">
            <div className="card-body d-flex flex-column justify-content-between">
                <div>
                    <h5 className="card-title">{item.promotion.description}</h5>
                    <p className="card-text">
                        {item.promotion.legalText}
                        <div className='d-flex flex-row justify-content-between'>
                            <div>Válido até <b>{moment(item.promotion.endDate).format('DD/MM')}</b></div>
                            {item.remainingMaxPerCustomer === -1
                            && <div>Utilizações Ilimitadas</div>
                            }
                            {item.remainingMaxPerCustomer > 0
                            && <div>Utilizações: {item.remainingMaxPerCustomer}  </div>
                            }
                            {item.remainingMaxPerCustomer === 0
                            && <div>Já utilizado</div>
                            }
                        </div>
                    </p>
                </div>
                {item.promotion.requiresActivation && !item.activated && item.remainingMaxPerCustomer!==0 ?
                    <Button color='primary' className='mt-2' block={true} onClick={() => ativarPromocao(item)}>Ativar Promoção</Button>
                    : 
                    <div className='btn btn-secondary mt-2 btn-block' ><FontAwesomeIcon icon={faThumbsUp} />  Promoção já ativada</div>
                }
            </div>
        </div >
    )
}

interface PromocaoItemProps {
    item: PromotionItem,
    carregarPromocoes: CallableFunction
}

export default PromocaoItemSpendAndGet;