import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import usePageTracking from '../../hooks/usePageTracking';
import LoadingService from '../../services/LoadingService';
import PromocaoService, { Mechanic } from '../../services/PromocaoService';
import { PromotionItem } from '../../types/PromotionItem';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPrint } from '@fortawesome/free-solid-svg-icons';
import LoginService from '../../services/LoginService';
import { useHistory } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import ModeloImpressao from './ModeloImpressao';
import PromocaoItemSpendAndGet from './PromocaoItemSpendAndGet';

const Promocoes = (props: any) => {
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    usePageTracking();
    const history = useHistory();
    const { authState } = useSelector((state: any) => state);
    const [carregando, setCarregando] = useState(true);
    const [promocoesDisponiveis, setPromocoesDisponiveis] = useState<PromotionItem[]>([]);
    const [promocoesAtivas, setPromocoesAtivas] = useState<PromotionItem[]>([]);

    const filterPromotions = (items: PromotionItem[]) => {
        return items;
    }

    const doLogout = () => {
        history.push('/login');
        LoginService.doLogout();
    }


    useEffect(() => {
        const res = promocoesDisponiveis.filter(item=>item.remainingMaxPerCustomer!==0).filter(item => !item.promotion.requiresActivation || (item.promotion.requiresActivation && item.activated));
        setPromocoesAtivas(res);
    }, [promocoesDisponiveis]);

    async function getPromocoesAtivas(): Promise<PromotionItem[]> {
        const res = await PromocaoService.getPromocoesByCpf(authState.data.Cpf, process.env.REACT_APP_MECHANIC as Mechanic);
        const ativas = filterPromotions(res.items);
        return ativas;

    }

    async function carregarPromocoes(): Promise<void> {
        const ativas = await getPromocoesAtivas();
        setPromocoesDisponiveis(ativas);
    }

    useEffect(() => {
        async function fetch() {
            setCarregando(true);
            LoadingService.show('Estamos verificando se existe oferta personalizada quentinha para você...');
            await carregarPromocoes();
            LoadingService.hide();
            setCarregando(false);
        }
        fetch();
    }, [authState.data.Cpf])

    return (
        <div className="container-fluid my-3">
            {!carregando &&
                <>
                    {promocoesDisponiveis.length > 0 ?
                        <>
                            <div className='d-flex flex-wrap justify-content-center m-n2'>
                                {promocoesDisponiveis.map(item => {
                                    return <PromocaoItemSpendAndGet item={item} key={item.promotionId} carregarPromocoes={carregarPromocoes} />
                                }
                                )}
                            </div>

                            {promocoesAtivas.length > 0 &&
                                <div style={{ display: "none" }} >
                                    <ModeloImpressao ref={componentRef} promocoes={promocoesAtivas} />
                                </div>
                            }
                        </>
                        : <p>Poxa, infelizmente não temos nenhuma promoção disponível no momento.<br />
                            Caso não faça parte do nosso programa de relacionamento, <a href="https://sejalevenatural.com.br/v1/apps/main#cadastro" title="Clique para se cadastrar">cadastre-se</a> e desfrute de todos os benefícios.
                        </p>}

                    <div className="d-flex justify-content-center mt-4">
                        <Button color='dark' type='button' className='m-2' onClick={() => doLogout()}><FontAwesomeIcon icon={faChevronLeft} /> Voltar</Button>
                    </div>
                </>
            }
        </div>
    )
}


export default Promocoes;